import React from 'react';
import compose from 'recompose/compose';
import LoginView from '../components/LoginView';
import withProps from "recompose/withProps";
import withFirebase, {withFirebaseProcess} from "../helpers/withFirebase";
import { withFirebaseAuth } from "@xem/user-common/classes/firebase";
import withUserLogin, { withLoginQuery } from "@xem/user-common/classes/withUserLogin";

const withLoginProcess = withProps(({ onSubmit, login, loginAction, client })=>({
	onSubmit: async variables =>
		loginAction({ action: login, variables, client, errorMsg: 'login.errorMsg'.t() })
			.catch(errorCatch)
}));


export default compose(
	withFirebase,
	withUserLogin,
	withLoginQuery,
	withFirebaseAuth,
	withFirebaseProcess,
	withLoginProcess
)(LoginView);
