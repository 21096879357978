import React from 'react';
import * as PropTypes from "prop-types";
import Button from 'reactstrap/lib/Button'

const Facebook = ({ small, Tags = Button, iconProps, style, children, ...props}) =>{
    const Icon = () => <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...iconProps}>
        <g id="icon_facebook" data-name="icon facebook" transform="translate(-74 -483)">
            <rect id="nền_40x40" data-name="nền 40x40" width="40" height="40" rx="5" transform="translate(74 483)" fill="#f2f2f2"/>
            <path id="facebook" d="M19,0H5A5,5,0,0,0,0,5V19a5,5,0,0,0,5,5H19a5,5,0,0,0,5-5V5a5,5,0,0,0-5-5ZM16,7H14.076C13.461,7,13,7.252,13,7.889V9h3l-.238,3H13v8H10V12H8V9h2V7.077C10,5.055,11.064,4,13.461,4H16Z" transform="translate(82 491)" fill="#1877f2"/>
        </g>
    </svg>

    return (<>
        { (!small ) && <>
            <Tags block className={'border-0 text-secondary'} style={{ minWidth: '160px', paddingTop: '0px', paddingBottom: '0px', '--btn-light': '#F2F2F2', ...style}} {...props}>
                <Icon />
                <span>{children || `facebook`.t().title()}</span>
            </Tags>
        </>}
        { (!!small)&&<Icon />}
    </>)
};


Facebook.propTypes = {
    iconProps: PropTypes.shape({
        width: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        height: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    }),
    className: PropTypes.node,
    Tags: PropTypes.node,
    small: PropTypes.bool
}


Facebook.defaultProps = {
    color: 'light',
    small: false,
    iconProps:{
        width: 40,
        height: 40
    }
}

export default Facebook;
