import React from 'react';
import TextField from "./TextField";

/**
 *
 * @type {function(*=): *}
 */
const Password = function RenderPassword({...props}){
  const [locked, changeState] = React.useState(true);
  let customType = locked ? 'password': null;

  const onClick = (e) =>{
    changeState(!locked)
  }

  Object.assign(props, {
    type: customType,
    eyeIcon:{ locked, eyeProps: { onClick } }
  })

  return (<TextField {...props} />)
};

export default Password;
