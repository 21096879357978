import React from 'react';
import Link from '@xem/look/Link';
import Row from '@gqlapp/base/Row';
import Col from '@gqlapp/base/Col';
import CardBody from 'reactstrap/lib/CardBody';
import LoginForm from "./form/LoginForm";
import WidgetBox from '@gqlapp/base/View/WidgetBox';
import View from '@gqlapp/base/View';
import Logo from "@xem/icon/header/logo.svg";
import { map } from '../helpers/config';
import Container from "reactstrap/lib/Container";
import FacebookButton from '@xem/icon/social/facebook';
import GoogleButton from '@xem/icon/social/google';
import {Helmet} from "react-helmet";


export const LoginView = ({ onSubmit, signInWithGoogle, signInWithFacebook, ...props })=> (<>
	<Helmet htmlAttributes={{class: 'view-anonymous web user-login'}}>
		<title>{`user:login`.t()}</title>
	</Helmet>
	<View className={'view-anonymous d-flex justify-content-center align-items-center'}>
		<Container fluid className={'px-0 px-md-2'}>
			<Row className={'row-n row-md-m'}>
				<Col className={'d-flex justify-content-center align-items-center'}>
					<WidgetBox className={'widget-anonymous-form widget-login hidden animated pulse shadow-md rounded-md-2 bg-white'}>
						<Row className={'row-n'}>
							<Col xs={12} className={'d-flex justify-content-center'} style={{ width: '416px', maxWidth: "100%"}}>
								<>
									<CardBody className={'p-3'}>
										<div style={{ width: '100%'}}>
											<div
												className={'text-center d-flex justify-content-center align-items-center'}
												style={{ height: '50px', width: '100%'
												}}>
												<Link href={'/'}><Logo height={'21px'} /></Link>
											</div>
											<div
												className={'text-center d-flex justify-content-center align-items-center'}
												style={{ height: '69px', width: '100%', paddingBottom: '30px'
												}}
											>
												<div className={'text-h2 fw-bold'}><>{'login.form.title'.t()}</></div>
											</div>
											<LoginForm {...props} onSubmit={onSubmit} />
										</div>
										<hr className={'hr-text mb-2'} data-content={'user:account.login.or'.t()}/>
										<Row className={'d-flex justify-content-center'}>
											<Col xs={'auto'} className={'d-flex align-items-stretch'}><FacebookButton onClick={signInWithFacebook} /></Col>
											<Col xs={'auto'} className={'d-flex align-items-stretch'}><GoogleButton onClick={signInWithGoogle} /></Col>
										</Row>
										<div className={'py-2'}>
											<div className={'pb-3 text-center'}>
												{'user:account.noExisted'.t()}&nbsp;
												<Link href={map({ path: 'register' })} className={'text-orange'}>
													{'user:register'.t()}
												</Link>
											</div>
										</div>
									</CardBody>
								</>
							</Col>
						</Row>
					</WidgetBox>
				</Col>
			</Row>
		</Container>
	</View>
</>)

export default LoginView;
