import React from 'react';
import * as PropTypes from "prop-types";
import Button from 'reactstrap/lib/Button'

const Google = ({ small, Tags = Button, iconProps, style, children, ...props}) =>{

	const Icon = () => <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" {...iconProps}>
		<g id="icongooogle" transform="translate(-56 -483)">
			<rect id="nền_40x40" data-name="nền 40x40" width="40" height="40" rx="5" transform="translate(56 483)" fill="#f2f2f2"/>
			<g id="icon_google" data-name="icon google" transform="translate(60 487)">
				<path id="Path_2768" data-name="Path 2768" d="M27.767,13.65H26.8V13.6H16v4.8h6.782a7.206,7.206,0,1,1-2.005-7.777l3.394-3.394A11.988,11.988,0,1,0,28,16,12.082,12.082,0,0,0,27.767,13.65Z" fill="#ffc107"/>
				<path id="Path_2769" data-name="Path 2769" d="M6.306,10.415l3.943,2.891A7.185,7.185,0,0,1,21.7,10.623l3.394-3.394A11.976,11.976,0,0,0,6.306,10.415Z" transform="translate(-0.922)" fill="#ff3d00"/>
				<path id="Path_2770" data-name="Path 2770" d="M16.878,37.622a11.944,11.944,0,0,0,8.045-3.115l-3.714-3.143a7.181,7.181,0,0,1-11.1-3.31L6.2,31.069A11.991,11.991,0,0,0,16.878,37.622Z" transform="translate(-0.878 -9.622)" fill="#4caf50"/>
				<path id="Path_2771" data-name="Path 2771" d="M35.767,20.05H34.8V20H24v4.8h6.782a7.224,7.224,0,0,1-2.452,3.343h0l3.714,3.143A11.608,11.608,0,0,0,36,22.4,12.082,12.082,0,0,0,35.767,20.05Z" transform="translate(-8 -6.4)" fill="#1976d2"/>
			</g>
		</g>
	</svg>

	return (<>
		{ (!small ) && <>
			<Tags block className={'border-0 text-secondary'} style={{ minWidth: '160px', paddingTop: '0px', paddingBottom: '0px', '--btn-light': '#F2F2F2', ...style}} {...props}>
				<Icon />
				<span>{children || `Google`.t().title()}</span>
			</Tags>
		</>}
		{ (!!small)&&<Icon />}
	</>)
};


Google.propTypes = {
	iconProps: PropTypes.shape({
		width: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		]),
		height: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number
		])
	}),
	className: PropTypes.node,
	Tags: PropTypes.node,
	small: PropTypes.bool
}


Google.defaultProps = {
	color: 'light',
	small: false,
	iconProps:{
		width: 40,
		height: 40
	}
}

export default Google;

