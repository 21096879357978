import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import Link from '@xem/look/Link';
import Field from '@gqlapp/forms-client-react/FieldAdapter';
import RenderField from "@xem/look/Renders/Field";
import RenderPassword from "@xem/look/Renders/Field/Password";
import Box from "@mui/material/Box";
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Row from '@gqlapp/base/Row';
import Col from '@gqlapp/base/Col';
import * as Yup from "yup";
import clsx from 'clsx';
import { map } from '../../helpers/config';


const LoginForm = withFormik({
  enableReinitialize: true,
  validationSchema:({ minUser = 4, minPass = 5 }) => Yup.object().shape({
    usernameOrEmail: Yup.string()
      .required('users.email.required'.t())
      .min(minUser, 'users.email.min'.t({ value: minUser })),
    password: Yup.string()
      .required('users.password.required'.t())
      .min(minPass, 'users.password.min'.t({ value: minPass}))
  }),
  mapPropsToValues: () => ({ usernameOrEmail: '', password: '' }),
  handleSubmit: (values, { props: { onSubmit }})=> onSubmit(values),
  displayName: 'LoginForm' // helps with React DevTools
})(({ view, handleSubmit, submitting, setFieldValue, values: { usernameOrEmail, password} }) => {
  return (
    <Form onSubmit={handleSubmit} {...{
        autoComplete: 'off'
    }}>

      <Box
        sx={{
          '& .MuiFormControl-root':{ marginTop: '8px', marginBottom: 0, minHeight: '56px' },
          '& .MuiFormHelperText-root': { fontSize: '12px', marginTop: '2px', lineHeight: '14px' }
        }}>
      <Field
        {...{ onRemove: setFieldValue }}
        {...{
          inputProps:{
            tabIndex: 1,
            autoComplete: 'new-usernameOrEmail',
            role:'presentation'
          },
          component: RenderField,
          name: 'usernameOrEmail',
          label: 'login.form.field.usernameOrEmail'.t(),
          value: usernameOrEmail
        }}
      />
      <Field
        {...{
          component: RenderPassword,
          inputProps:{
            tabIndex: 2,
            autoComplete: 'new-password',
            role:'presentation'
          },
          name: 'password',
          type: view ? 'text': 'password',
          label: 'login.form.field.pass'.t(),
          value: password
        }}
      />
      </Box>
      <Row className={'mt--3 pb-3'}>
          <Col className={'text-right'}>
            <Link href={map({ path: 'forgot' })} className={clsx('text-primary')}>{'login.btn.forgotPass'.t()}</Link>
          </Col>
      </Row>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Button
            size={"lg"} block
            color={"primary"}
            className={clsx('text-uppercase')}
            type={"submit"}
            disabled={submitting}
        >
          {'login.form.btnSubmit'.t()}
        </Button>
      </div>
    </Form>
  );
});

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  errors: PropTypes.object,
  values: PropTypes.object
};

export default LoginForm;
